<template>
    <div>
        <ul class="pagination">
            <li class="page-item">
                <button class="btn" v-on:click.prevent="setPage(currentPage - 1)" v-bind:disabled="currentPage <= 1"
                    id="paginationPreviousButton">
                    <i class="ti ti-chevron-left"></i>
                    Anterior
                </button>
            </li>

            <li class="page-item m-1" v-for="page in pages" v-bind:key="page"
                v-bind:class="{ active: page === currentPage }">
                <a class="page-item page-link" href="#" v-on:click.prevent="setPage(page)" id="paginationCurrentPage">
                    {{ page }}
                </a>
            </li>

            <li class="page-item px-2" v-if="totalPages > maxVisiblePageItems">
                <input id="paginationCurrentPageInput" v-model="currentPageInput" type="number"
                    class="form-control input-page" @change="setPage(currentPageInput)" />
            </li>

            <li class="page-item ml-1">
                <button class="btn" href="#" v-on:click.prevent="setPage(currentPage + 1)"
                    v-bind:disabled="currentPage >= totalPages" id="paginationNextButton">
                    Próxima
                    <i class="ti ti-chevron-right"></i>
                </button>
            </li>

            <li class="page-item m-2">
                <strong>
                    <span v-if="total == 10000">+</span>{{ total }} item(s)</strong>
                - {{ currentPage }} de {{ totalPages }} página(s)
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "Pagination",
    props: {
        showPerPage: {
            type: Number,
            default: 20
        },
        currentPage: {
            type: Number,
            default: 1
        },
        total: {
            type: Number,
            default: 0
        }
    },
    emits: ["pageChange"],
    data() {
        return {
            totalPages: 0,
            maxVisiblePageItems: 5,
            currentPageInput: 1,
            pages: []
        }
    },
    mounted() {
        this.currentPageInput = this.currentPage
        this.updateTotalPages()
        this.$watch("currentPage", (newVal) => {
            this.currentPageInput = newVal
        })
        this.$watch("total", () => {
            this.updateTotalPages()
        })
    },
    methods: {
        setPage(page) {

            if (page > this.totalPages) {
                page = this.totalPages
            }

            if (page <= 0) {
                page = 1
            }
            this.$emit("pageChange", page)
        },
        updateTotalPages() {
            this.pages = []
            this.totalPages = Math.ceil(this.total / this.showPerPage)
            if (this.totalPages <= this.maxVisiblePageItems) {
                for (let i = 1; i <= this.totalPages; i++) {
                    this.pages.push(i)
                }
            }
        }
    }
}
</script>

<style scoped>
.input-page {
    width: 60px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}
</style>

<template>
    <div>
        <h2>Redirecionando...</h2>
    </div>
</template>

<script>
import Api from "../services/api"
import EventBus from "../services/event-bus"

export default {
    name: "Oauth2CallbackPage",
    async mounted() {
        const data = this.$route.query
        const state = data.state

        if (!state) {
            EventBus.emit("message", {
                type: "danger",
                message: "Erro ao autorizar conta: Dados de retorno inválidos"
            })
            return this.$router.push("/service-accounts")
        }

        try {
            const serviceAccountId = state.split("_")[0]

            await Api.serviceAccounts.updateOauth2(
                serviceAccountId,
                { ...data, grant_type: "authorization_code" }
            )

            EventBus.emit("message", {
                type: "success",
                message: "Conta autorizada com sucesso!"
            })
        } catch (err) {
            const error_message =
                err.response.data.message ??
                err.response.data.error ??
                err.response.data

            EventBus.emit("message", {
                type: "danger",
                message: "Erro ao autorizar conta: " + error_message
            })
        }

        this.$router.push("/service-accounts")
    }
}
</script>

<template>
    <div class="card-header">
        <div class="col">
            <h3 class="card-title">
                <i class="ti ti-files"></i>
                Storage
            </h3>
        </div>
        <div class="col-auto mr-4">
            <slot></slot>
        </div>
        <div class="col-auto">
        <slot name="button2"></slot>

        </div>
    </div>
</template>


<script>

export default {
    name: "HeaderButton",
    props: {
        title: {
            type: String,
            default: ""
        },
        titleButton: {
            type: String,
            default: ""
        },
    },
    async mounted() {
        this.items = [
            {
                title: 'Dashboard',
                disabled: false,
                href: 'breadcrumbs_dashboard',
            }
        ]

    }
}
</script>
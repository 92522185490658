<template>
    <div class="mb-3">
        <label class="form-label required">Serviço</label>
        <select
            v-model="localOauth2.service_type"
            class="form-select"
            @change="changeServiceType"
        >
            <option value="bling_erp_oauth2">Bling ERP</option>
            <option value="custom">Customizado</option>
        </select>
    </div>

    <div v-if="showUrl" class="mb-3">
        <label class="form-label required">URL do serviço</label>
        <input
            id="serviceAccountOauth2UrlInput"
            v-model="localServiceAccount.url"
            type="text"
            class="form-control"
            @input="updateValue('serviceAccount')"
        />
    </div>

    <div v-if="showAuthorizeUrl" class="mb-3">
        <label class="form-label required">URL de autorização</label>
        <input
            id="serviceAccountOauth2AuthorizeUrlInput"
            v-model="localOauth2.authorize_url"
            type="text"
            class="form-control"
            @input="updateValue('oauth2')"
        />
    </div>

    <div class="mb-3">
        <div class="row">
            <div v-if="showClientId" class="col">
                <label class="form-label required">Client ID</label>
                <input
                    id="serviceAccountOauth2ClientIdInput"
                    v-model="localOauth2.client_id"
                    type="text"
                    class="form-control"
                    placeholder="Client ID"
                    @input="updateValue('oauth2')"
                />
            </div>
            <div v-if="showClientSecret" class="col">
                <label class="form-label required">Client Secret</label>
                <input
                    id="serviceAccountOauth2ClientSecretInput"
                    v-model="localOauth2.client_secret"
                    type="password"
                    class="form-control"
                    placeholder="Client Secret"
                    @input="updateValue('oauth2')"
                />
            </div>
        </div>
    </div>

    <div v-if="localOauth2.service_type == 'bling_erp_oauth2'" class="mb-3">
        <div class="col">
            <p>
                Seu app precisa redirecionar o usuário para a URL:
                <b>{{ baseUrl }}/oauth2/callback</b>
                <i
                    class="ti ti-copy cursor-pointer ms-1"
                    @click="copyContent(baseUrl + '/oauth2/callback')"
                ></i>
            </p>
        </div>
    </div>
</template>

<script>
import EventBus from "@/services/event-bus"

export default {
    name: "OAuth2ServiceAccount",
    props: {
        serviceAccount: {
            type: Object,
            required: true
        },
        oauth2: {
            type: Object,
            required: true
        }
    },
    emits: ["update:serviceAccount", "update:oauth2"],

    data() {
        return {
            showUrl: false,
            showAuthorizeUrl: false,
            showClientId: false,
            showClientSecret: false,

            localServiceAccount: this.serviceAccount,
            localOauth2: this.oauth2
        }
    },

    computed: {
        baseUrl() {
            return window.location.origin
        },

        defaultValues() {
            return {
                bling_erp_oauth2: {
                    serviceAccount: {
                        url: "https://api.bling.com.br/Api/v3"
                    }
                }
            }
        }
    },

    watch: {
        serviceAccount: {
            handler: function (val) {
                this.localServiceAccount = val
            },
            deep: true
        },

        oauth2: {
            handler: function (val) {
                this.localOauth2 = val
            },
            deep: true
        }
    },

    mounted: function () {
        this.changeServiceType()
    },

    methods: {
        changeServiceType() {
            const serviceType = this.localOauth2.service_type

            this.showUrl = this.showInput(serviceType, "url")
            this.showAuthorizeUrl = this.showInput(serviceType, "authorize_url")
            this.showClientId = this.showInput(serviceType, "client_id")
            this.showClientSecret = this.showInput(serviceType, "client_secret")

            this.setDefaultValues()
            
            this.updateValue("serviceAccount")
            this.updateValue("oauth2")
        },

        setDefaultValues() {
            const defaultValues =
                this.defaultValues[this.localOauth2.service_type]

            if (!defaultValues) {
                return
            }

            this.localServiceAccount = {
                ...this.localServiceAccount,
                ...defaultValues.serviceAccount
            }

            this.localOauth2 = {
                ...this.localOauth2,
                ...defaultValues.oauth2
            }
        },

        showInput(authType, input) {
            const inputs = {
                bling_erp_oauth2: ["url", "client_id", "client_secret"]
            }

            if (!inputs[authType]) {
                return true
            }

            return inputs[authType].includes(input)
        },

        copyContent(content) {
            navigator.clipboard.writeText(content)
            EventBus.emit("message", {
                type: "success",
                message: "Conteúdo copiado para a área de transferência"
            })
        },

        updateValue(type) {
            const values = {
                serviceAccount: this.localServiceAccount,
                oauth2: this.localOauth2
            }

            this.$emit(`update:${type}`, values[type])
        }
    }
}
</script>

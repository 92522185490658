<template>
    <div>
        <div id="modal-storage-form" class="modal modal-blur fade" tabindex="-1" style="display: none" aria-modal="true"
            role="dialog">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" v-if="action === 'upload'">Upload de arquivo</h5>
                        <h5 class="modal-title" v-else>Criar pasta</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" id="closeFormButton"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col">
                                <div class="mb-4">
                                    <label class="form-label mt-4">Insira o nome da pasta
                                    </label>
                                    <div class="input-group mt-1">
                                        <span class="input-group-text">
                                            {{ transformStartPath(startPath) }}
                                        </span>
                                        <input type="text" class="form-control " v-model="pathFile"
                                            :placeholder="pathFile" autocomplete="off" />
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div v-if="action === 'upload'" class="row">
                            <label class="form-label">Selecione o arquivo</label>
                            <div class="col">
                                <v-file-input v-model="file" density="compact" prepend-icon="ti ti-upload">
                                </v-file-input>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-link link-secondary" data-bs-dismiss="modal" id="cancelButton"
                            @click="cancel()">
                            Cancelar
                        </button>
                        <button class="btn btn-primary ms-auto" data-bs-dismiss="modal" id="saveVariableButton"
                            @click="save()">
                            <div v-if="action === 'upload'">
                                Enviar
                            </div>
                            <div v-else>
                                Criar
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "VariableForm",
    props: {
        action: {
            type: String,
            required: true,
            default: "upload"
        },
        data: {
            type: Object,
            required: false,
            default: null
        }
    },
    data() {
        return {
            actionTitle: "Upload de arquivo",
            pathFile: "",
            startPath: this.$route.query.path || "/",
            newPath: "",
            file: ""
        }
    },
    mounted() {
        if (this.action === "folder") {
            this.actionTitle = "Nova pasta"
        }

        this.$watch("data", (newData) => {
            this.variable = newData
        })
    },
    methods: {
        cancel() {
            this.$emit("cancel")
        },
        save() {
            if (this.action === "upload") {
                // atualiza o estado newPath
                this.transformPath(this.startPath, this.pathFile)

                this.$emit("upload", { file: this.file, pathFile: this.newPath });
                return;
            }
            if (this.action === "folder") {
                // atualiza o estado newPath
                this.transformPath(this.startPath, this.pathFile)

                this.$emit("newFolder", { pathFile: this.newPath });
                return;
            }

        },
        transformStartPath(startPath) {
            const lastLetterStartPath = startPath[startPath.length - 1]
            if (lastLetterStartPath === "/") {
                return this.startPath = startPath
            }
            this.startPath = startPath + "/"
            return this.startPath
        },
        transformPath(startPath, pathFile) {
            const lastLetterPathFile = pathFile[pathFile.length - 1]
            if (lastLetterPathFile === "/") {
                pathFile = pathFile.slice(0, -1)
            }

            this.newPath = startPath + pathFile

            return startPath + pathFile
        }
    }
}
</script>

<template>
    <div class="page-wrapper">
        <div class="row mb-2">
            <div class="col">
                <WorkspaceEngineStatus :engine-restarting="restarting" />
            </div>
        </div>

        <div class="row">
            <div class="col">
                <div class="card">
                    <div class="row m-3 mb-3">
                        <div class="col">
                            <h3 class="card-title m-0">Execução em tempo real</h3>
                        </div>
                        <div class="col-auto">
                            <span
                                v-if="statusList.length"
                                class="badge bg-green-lt h-100 d-flex "
                                >{{ statusList.length }} flow(s) em
                                execução</span
                            >
                            <span v-else class="badge bg-blue-lt h-100 d-flex"
                                >0 flow(s) em execução</span
                            >
                        </div>

                        <div class="col-auto">
                            <button 
                                id="restartEngineButton" 
                                class="btn btn-sm btn-danger"
                                data-bs-target="#modal-restart-engine-form"
                                data-bs-toggle="modal"
                                :disabled="restarting"
                            > 
                                <i class="ti ti-alert-triangle"></i>
                                Reiniciar engine
                            </button>
                        </div>
                    </div>

                    <div v-show="!statusList.length" class="card">
                        <div class="card-body">
                            <p class="text-muted">
                                <span class="execution-spinner"></span>
                                Aguardando execuções...
                            </p>
                        </div>
                    </div>

                    <div v-show="statusList.length">
                        <table class="table table-vcenter card-table">
                            <thead>
                                <tr>
                                    <th>Status</th>
                                    <th>Flow</th>
                                    <th>Iniciador</th>
                                    <th>ID da execução</th>
                                    <th>Inspecionar</th>
                                    <th>Iniciado em</th>
                                    <th>Tempo de execução</th>
                                    <th>Parar a execução</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="status in statusList"
                                    :key="status.execution_id"
                                >
                                    <td>
                                        <div
                                            class="badge bg-green text-green-fg"
                                        >
                                            Executando
                                        </div>
                                    </td>
                                    <td>
                                        <a
                                            :href="'/flows/' + status.flow_id"
                                            target="_blank"
                                            >{{ status.flow_name }}</a
                                        >
                                    </td>
                                    <td>
                                        {{ getNodeName(status.trigger_type) }}
                                        <small class="text-muted"
                                            >UID:
                                            {{ status.trigger_node_uid }}</small
                                        >
                                    </td>
                                    <td>{{ status.execution_id }} <br /></td>
                                    <td>
                                        <button
                                            class="btn btn-sm btn-outline-primary goToLogsButton"
                                            @click="goToLogs(status)"
                                        >
                                            <i class="ti ti-search"></i>
                                            Ver logs
                                        </button>
                                    </td>
                                    <td>
                                        <small class="text-muted">{{
                                            formatDatetime(status.start_time)
                                        }}</small>
                                    </td>
                                    <td>
                                        <span class="badge bg-blue-lt">{{
                                            getElapsedTime(status.start_time)
                                        }}</span>
                                    </td>
                                    <td>
                                        <button
                                            class="btn btn-sm btn-danger stopExecution"
                                            :disabled="
                                                status.loadingDeleteRequest
                                            "
                                            @click="
                                                stopExecution(
                                                    status.execution_id
                                                )
                                            "
                                        >
                                            <span
                                                v-if="
                                                    !status.loadingDeleteRequest
                                                "
                                            >
                                                Stop
                                            </span>
                                            <span v-else> Stopping </span>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <RestartEngineForm
            @restart="restartEngine"
        />
    </div>
</template>

<script>
import moment from "moment-timezone"

import { store } from "@/store"
import EventBus from "@/services/event-bus"
import EngineApi from "@/services/engine-api"
import WorkspaceEngineStatus from "@/components/WorkspaceEngineStatus"
import RestartEngineForm from "@/components/RestartEngineForm"

export default {
    name: "MonitoringActiveFlowsPage",
    components: {
        WorkspaceEngineStatus,
        RestartEngineForm
    },

    data() {
        return {
            socket: null,
            statusList: [],
            interval: null,
            nodesLibrary: [],
            restarting: false
        }
    },

    beforeUnmount() {
        this.closeRealtimeData()
    },

    async mounted() {
        moment.locale("pt-br")

        EventBus.emit("set-header-title", "Flows ativos em tempo real")

        var nodesLibraryResponse = await EngineApi.getNodesLibrary()
        this.nodesLibrary = nodesLibraryResponse.data

        this.initRealtimeData()
    },

    methods: {
        async initRealtimeData() {
            var url = store.workspace.engine_url + "live-monitoring"

            this.socket = io(url)

            this.socket.on("connect", () => {
                console.log(
                    "%c🚀 Floui Live Monitoring connected!",
                    "color: #206bc4"
                )
            })

            // on demand update
            this.socket.on("status-list-updated", (data) => {
                this.statusList = data.map((ObjExecution) => {
                    return {
                        ...ObjExecution,
                        loadingDeleteRequest: ObjExecution.stop_execution ? true : false
                    }
                })
            })

            // interval update
            this.socket.on("status-list", (data) => {
                this.statusList = data.map((ObjExecution) => {
                    return {
                        ...ObjExecution,
                        loadingDeleteRequest: ObjExecution.stop_execution ? true : false
                    }
                })
            })

            this.socket.on("disconnect", () => {
                console.log(
                    "%cFloui Live Monitoring disconnected!",
                    "color: #ccc"
                )
            })

            this.interval = setInterval(() => {
                if (this.restarting) {
                    return
                }

                this.socket.emit("get-status-list")
            }, 1000)
        },

        closeRealtimeData() {
            clearInterval(this.interval)
            this.socket.disconnect()
        },

        getElapsedTime(start_time) {
            var start = moment(start_time)
            var end = moment()
            var duration = moment.duration(end.diff(start))

            return moment.utc(duration.asMilliseconds()).format("HH:mm:ss")
        },

        getFromNow(string) {
            return moment(string).fromNow()
        },

        formatDatetime(string) {
            return moment(string).format("DD/MM/YYYY HH:mm:ss")
        },

        getNodeByType(type) {
            return this.nodesLibrary.nodes.find((node) => node.name == type)
        },

        getNodeName(type) {
            var node = this.getNodeByType(type)
            return node ? node.label : type
        },

        goToLogs(status) {
            this.$router.push({
                path: "/monitoring",
                query: {
                    filters: JSON.stringify({
                        date_alias: "1000d",
                        execution_id: status.execution_id
                    })
                }
            })
        },

        async stopExecution(execution_id) {
            this.statusList = this.statusList?.map((ObjExecution) => {
                if (ObjExecution.execution_id === execution_id) {
                    return { ...ObjExecution, loadingDeleteRequest: true }
                }
                return ObjExecution
            })
            const body = {
                execution_id
            }
            await EngineApi.flows.stopExecution(body)
        },

        async restartEngine() {
            // Interrompe o monitoramento para evitar erros no console
            this.closeRealtimeData()
            this.restarting = true

            try {
                await EngineApi.flows.restartEngine()

                EventBus.emit("message", {
                    type: "success",
                    message: "Engine reiniciada! Aguarde alguns intantes..."
                })
            } catch (error) {
                EventBus.emit("message", {
                    type: "danger",
                    message: "Algo deu errado ao reiniciar a engine!"
                })

                console.error(error)
            }

            // Espera 3 segundos para reiniciar o monitoramento
            setTimeout(() => {
                this.initRealtimeData()
                this.restarting = false
            }, 3000)
        }
    }
}
</script>

<style scoped>
#executions-chart {
    height: 160px;
}

.execution-spinner {
    width: 16px;
    height: 16px;
    border: 3px solid #ddd;
    border-bottom-color: #333;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
